<template>
  <div class="regist-box">
    <el-form
      ref="form"
      :model="formData"
      :rules="formRules"
      label-width="80px"
    >
      <el-form-item label-width="0">
        <div class="logo"></div>
        <div class="title">{{ title }}</div>
      </el-form-item>
      <el-form-item
        label="手机号"
        prop="phone"
      >
        <el-input
          v-model="formData.phone"
          placeholder="输入手机号"
          @keyup.enter.native="onRegist"
        >
        </el-input>
      </el-form-item>
      <el-form-item
        label="验证码"
        prop="verify"
      >
        <div class="verify-box">
          <el-input
            v-model="formData.verify"
            placeholder="输入验证码"
            @keyup.enter.native="onRegist"
          >
          </el-input>
          <el-button
            type="primary"
            :disabled="verifyDisabled"
            @click="onGetVerify"
          >{{ verifyText }}</el-button>
        </div>
      </el-form-item>
      <el-form-item
        label="密码"
        prop="password"
      >
        <el-input
          v-model="formData.password"
          type="password"
          placeholder="输入密码"
          @keyup.enter.native="onRegist"
        >
        </el-input>
      </el-form-item>
      <el-form-item
        label="确认密码"
        prop="password_confirmation"
      >
        <el-input
          v-model="formData.password_confirmation"
          type="password"
          placeholder="输入密码"
          @keyup.enter.native="onRegist"
        >
        </el-input>
      </el-form-item>
      <el-form-item style="margin-top: -15px;">
        <div class="regist-box">
          <el-link
            type="primary"
            :underline="false"
            @click="$router.push({ name: 'login' })"
          >去登录</el-link>
        </div>
      </el-form-item>
      <el-form-item label-width="0">
        <el-button
          type="primary"
          block
          :loading="loading"
          @click.native.prevent="onRegist"
        >确定</el-button>
      </el-form-item>
    </el-form>
  </div>

</template>

<script>
import { userPassword, getSms } from '@/api/admin'

export default {
  data() {
    const passwordValid = (rule, value, callback) => {
      if (
        this.formData.password &&
        this.formData.password_confirmation &&
        this.formData.password !== this.formData.password_confirmation
      ) {
        callback(new Error('俩次输入密码不相同'))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      title: '农牧厅精准监督',
      verifyText: '获取验证码',
      verifyDisabled: false,
      verifyTimer: null,
      formData: {
        phone: '',
        verify: '',
        password: '',
        password_confirmation: '',
      },
      formRules: {
        phone: [
          { required: true, message: '必填项', trigger: 'blur' },
          { validator: this.$validator.isPhone },
        ],
        verify: [{ required: true, message: '必填项', trigger: 'change' }],
        password: [
          { required: true, message: '必填项', trigger: 'blur' },
          { validator: passwordValid },
        ],
        password_confirmation: [
          { required: true, message: '必填项', trigger: 'blur' },
          { validator: passwordValid },
        ],
      },
    }
  },
  methods: {
    fetchRegist() {
      this.loading = true
      userPassword(this.formData)
        .then(res => {
          this.$message.success('成功修改密码')
          this.$router.replace({ name: 'login' })
        })
        .finally(() => {
          this.loading = false
        })
    },
    onRegist() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.fetchRegist()
        }
      })
    },
    onGetVerify() {
      this.$refs.form.validateField('phone', valid => {
        if (!valid) {
          this.verifyDisabled = true
          getSms(this.formData.phone, 'change_password')
            .then(res => {
              let count = 60
              this.verifyText = `重新获取 ${count}s`
              this.verifyTimer = setInterval(() => {
                if (count < 1) {
                  this.verifyText = '获取验证码'
                  this.verifyDisabled = false
                  clearInterval(this.verifyTimer)
                  this.verifyTimer = null
                } else {
                  count--
                  this.verifyText = `重新获取 ${count}s`
                }
              }, 1000)

              this.$once('hook:beforeDestroy', () => {
                if (this.verifyTimer) {
                  clearInterval(this.verifyTimer)
                  this.verifyTimer = null
                }
              })
            })
            .catch(() => {
              this.verifyDisabled = false
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.regist-box {
  .logo {
    width: 120px;
    height: 80px;
    margin: 0 auto;
    background: url('~@/assets/images/logo.png') center / contain no-repeat;
  }

  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: #000;
    text-align: center;
  }

  /deep/ {
    .el-input__inner {
      border: none;
      border-bottom: 1px solid #dcdfe6;
    }
  }

  .regist-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
  }

  .verify-box {
    display: flex;

    .el-button {
      width: 160px;
    }
  }
}
</style>
